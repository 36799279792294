import React, { useRef, useEffect, useState } from 'react';
// import { HexColorPicker } from 'react-colorful';  // Import the HexColorPicker component
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import "./canvas.css";

const CanvasComponent = () => {
    const canvasRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [dragging, setDragging] = useState(false);
    const [origin, setOrigin] = useState({ x: 0, y: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [drawData, setDrawData] = useState([]);
    const [currentColor, setCurrentColor] = useState('#000000');  // State to track the current color
    const [pickerVisible, setPickerVisible] = useState(false);  // State to control the visibility of the color picker
    const [selectedPixel, setSelectedPixel] = useState(null);
    const [selectedPixelPos, setSelectedPixelPos] = useState({ x: 0, y: 0 });
    const [username, setUsername] = useState('???');
    const [userId, setUserId] = useState(-1);
    const [pixelsLeft, setPixelsLeft] = useState(0);
    const [infoBoxPosition, setInfoBoxPosition] = useState({x: 0, y: 0});
    const [socket, setSocket] = useState(null);
    const [startDistance, setStartDistance] = useState(null);

    const [colorOptions, setColorOptions] = useState([]);
    const [canvasSize, setCanvasSize] = useState(1000);

    const [lb, setLb] = useState([]);
    const [lbVisible, setLbVisible] = useState(false);
    const [lbPage, setLbPage] = useState(0);

    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState('');

    const [giftDialogVisible, setGiftDialogVisible] = useState(false);
    const [giftAmount, setGiftAmount] = useState(1);
    const [giftMethod, setGiftMethod] = useState(1); // 0 = default, 1 = a friend, 2 = random
    const [giftText, setGiftText] = useState("Select a gift option above.");
    const [redeemCode, setRedeemCode] = useState('');
    const [giftCodes, setGiftCodes] = useState([]);

    const contextRef = useRef(null);

    const [debugText, setDebugText] = useState('Sign in');

    useEffect(() => {
        if (giftMethod === 0) {
            setGiftText("Select a gift option above.");
        } else if (giftMethod === 1) {
            setGiftText("Get a gift code to send to a friend or other user.");
        } else if (giftMethod === 2) {
            setGiftText("Give pixels randomly to the canvas' most active users.");
        }
    }, [giftMethod]);

    const DEBUG = false;

    const handleRedeem = async () => {
        try {
            const type = Cookies.get('signType');
            const signature = Cookies.get('userSignature');
            const response = await fetch('/redeem', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    signature: (type === 'sol' ? Buffer.from(signature, 'base64') : signature),
                    type,
                    publicKey: Cookies.get('key'),
                    redeemCode,
                })
            });

            const result = await response.json();
            console.log(result);
            setRedeemCode('');
            await updateUserData();
            alert("Code has been redeemed!");
        } catch {
            alert("Invalid code.");
        }
    };


    const handleGiftAmountChange = (event) => {
        const value = Math.max(1, Math.min(pixelsLeft, parseInt(event.target.value, 10)));
        setGiftAmount(Number.isNaN(value) ? '' : value);
    };

    const sendGift = async () => {
        try {
            const type = Cookies.get('signType');
            const signature = Cookies.get('userSignature');
            const body = {
                signature: (type === 'sol' ? Buffer.from(signature, 'base64') : signature),
                type,
                publicKey: Cookies.get('key'),
                pixels: giftAmount,
                method: giftMethod,
            };
    
            const response = await fetch('/gift', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            });
    
            // const result = await response.json();
            // setGiftDialogVisible(false);
    
            await updateUserData();
        } catch (error) {
            alert("Error sending: " + error);
        }
    };

    const navigate = useNavigate();

    const onNoteChange = (e) => {
        setNote(e.target.value);
    }

    const updateUserData = async () => {
        const signature = Cookies.get('userSignature');
        if (signature) {
            try {
                const url = '/getdata';
                const type = Cookies.get('signType');
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ signature: (type === 'sol' ? Buffer.from(signature, 'base64') : signature), type, publicKey: Cookies.get('key') })
                });
            
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const data = await response.json();
                setUsername(data.user.name);
                setPixelsLeft(data.user.pixels);
                setUserId(data.user.id);
                setGiftCodes(data.user.giftCodes || []);
            } catch (error) {
                console.error('Error fetching user data:', error);
                fetchLeaderboard();
            }
        }
    };

    const fetchLeaderboard = async () => {
        try {
            const response = await fetch('/leaderboard?id=' + userId);
            const data = await response.json();
            setLb(data);
        } catch (error) {
            // alert("Error getting Lb: " + error);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            await updateUserData();
            await fetchLeaderboard();


            const colorResponse = await fetch('/colors');
            const cols = await colorResponse.json();

            setColorOptions(cols.colors);
            setCanvasSize(cols.size);

            if (!socket) {
                startSocket();
            }
        };

        fetchUserData();
        setInterval(fetchLeaderboard, 10000);
    }, []);
    
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // const dpi = window.devicePixelRatio;
        // canvas.getContext('2d').scale(dpi, dpi);

        ctx.msImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
 
        const ratio = Math.ceil(window.devicePixelRatio);
        canvas.width = canvasSize * ratio;
        canvas.height = canvasSize * ratio;
        canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);

        // Redraw stored paths
        drawData.forEach(data => {
            ctx.fillStyle = data.color || 'black';  // Default to black if no color specified
            ctx.fillRect(data.x + canvasSize / 2, data.y + canvasSize / 2, 1, 1);
        });
    }, [drawData]);

    function startSocket() {
        const newSocket = io(DEBUG ? 'http://localhost:5000' : '');
        setSocket(newSocket);
        newSocket.on('pixel placed', (pixel) => {
            // pixel.x -= canvasSize / 2;
            // pixel.y -= canvasSize / 2;
            setDrawData(prevDrawData => [...prevDrawData, pixel]);
            // drawNewPixel(pixel);

            if (pixel.note && pixel.note.length > 0 && pixel.note.length <= 120) {
                const s = Math.random();
    
                const newNote = {
                    id: s,
                    text: pixel.note,
                    x: pixel.x,
                    y: pixel.y,
                    fading: false,
                    timeout: setTimeout(() => {
                        triggerFadeOut(s);
                    }, 4000)
                };

                setNotes(prevNotes => [...prevNotes, newNote]);
            }
        });

        // if (DEBUG) {
        //     const randomPixels = [];
        //     for (let i = 0; i < 1000; i++) {
        //         randomPixels.push({
        //             x: Math.floor(Math.random() * 1001) - 500, // Random x between -500 and 500
        //             y: Math.floor(Math.random() * 1001) - 500, // Random y between -500 and 500
        //             color: '#' + Math.floor(Math.random() * 16777215).toString(16), // Random color
        //             user: `user${Math.floor(Math.random() * 10000)}`, // Random user ID
        //             id: Math.floor(Math.random() * 1000000), // Random ID
        //             note: "This is a test note! Hello!",
        //             date: new Date() // Current dates
        //         });
        //     }

        //     setDrawData(randomPixels);
        // }
            
        return () => newSocket.close();
    }

    useEffect(() => {
        // const canvas = canvasRef.current;
        // const ctx = canvas.getContext('2d');

        // const PIXEL_RATIO = window.devicePixelRatio || 1;

        // canvas.width = canvasSize * PIXEL_RATIO;
        // canvas.height = canvasSize * PIXEL_RATIO;

        // canvas.style.width = `${canvasSize}px`;
        // canvas.style.height = `${canvasSize}px`;

        // ctx.setTransform(PIXEL_RATIO, 0, 0, PIXEL_RATIO, 0, 0);

        // ctx.imageSmoothingEnabled = false;

        // contextRef.current = ctx;

        fetchPixels();
    }, []);

    const fetchPixels = async () => {
        try {
        let moreData = true;

        for(let page = 1; moreData; page++) {
            await fetch(`/pixels-test?page=${page}`)
            .then(response => response.json())
            .then(pixels => {

                if (pixels.length > 0) {
                    setDrawData(drawData => [...drawData, ...pixels]);
                } else {
                    moreData = false;
                }

                // const canvas = canvasRef.current;
                // const ctx = canvas.getContext('2d');
    
                // const between = 5000 / data.total;

                // pixelDataRef.current = pixelDataRef.current.concat(pixels);
                // drawPixels();
    
                // pixels.forEach((pixel, index) => {
    
                // setTimeout(() => {
                //     const { x, y, color } = pixel;
                //     ctx.fillStyle = color;
                //     ctx.fillRect(x + 1000 / 2, y + 1000 / 2, 1, 1); // todo: fix this when the canvas gets bigger
                // }, between);
                // });
            })
            .catch(error => console.error('Error fetching pixels:', error));
        }
        } catch (error) {
            alert("Error fetching: " + error);
        }
    };

    const triggerFadeOut = (id) => {
        setNotes(prevNotes => prevNotes.map(note => note.id === id ? { ...note, fading: true} : note));
        setTimeout(() => {
            removeNoteById(id);
        }, 1000);
    }

    const removeNoteById = (id) => {
        setNotes(prevNotes => prevNotes.filter(note => note.id !== id));
    }

    const handleNoteClick = (id) => {
        triggerFadeOut(id);
        // clearTimeout(notes.find(note => note.id === id)?.timeout);
        // removeNoteById(id);
    };

    function sanitizeInput(input) {
        if (input === undefined) {
            return '';
        }

        try {
            return input
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#39;");
        }
        catch {
            return '';
        }
    }

    const renderNotes = () => {
        return notes.map((note) => (
            <div
                key={note.id}
                style={{
                    position: 'absolute',
                    left: `${(getScreenCoordinates(note.x, note.y).screenX)}px`,
                    top: `${getScreenCoordinates(note.x, note.y).screenY}px`,
                    padding: '5px',
                    backgroundColor: 'white',
                    border: '1px solid black',
                    cursor: 'pointer',
                    zIndex: 10,
                    animation: 'fadeOut 5s forwards',
                }}
                onClick={() => handleNoteClick(note.id)}
                >
                    {sanitizeInput(note.text)}
                </div>
        ));
    };

    const getScreenCoordinates = (canvasX, canvasY) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        
        // Reverse the calculations
        const screenX = (canvasX + (canvasSize / 2)) * scale + rect.left;
        const screenY = (canvasY + (canvasSize / 2)) * scale + rect.top;
    
        return { screenX, screenY };
    };


    // useEffect(() => {
    //     const newSocket = io(DEBUG ? 'http://localhost:5000' : '');
    //     setSocket(newSocket);
    //     newSocket.on('pixel placed', (pixel) => {
    //         setDrawData(prevDrawData => [...prevDrawData, pixel]);
    //     });

    //     fetch((DEBUG ? 'http://localhost:5000' : '') + '/pixels')
    //         .then(response => response.json())
    //         .then(data => {
    //             setDrawData(data);  // Assuming the data is an array of pixel objects
    //         })
    //         .catch(error => console.error('Failed to fetch pixels:', error));
            
    //         return () => newSocket.close();
    // }, []);

    // useEffect(() => {
    //     const canvas = canvasRef.current;
    //     const ctx = canvas.getContext('2d');

    //     // const dpi = window.devicePixelRatio;
    //     // canvas.getContext('2d').scale(dpi, dpi);

    //     ctx.msImageSmoothingEnabled = false;
    //     ctx.mozImageSmoothingEnabled = false;
    //     ctx.webkitImageSmoothingEnabled = false;
    //     ctx.imageSmoothingEnabled = false;
 
    //     const ratio = Math.ceil(window.devicePixelRatio);
    //     canvas.width = canvasSize * ratio;
    //     canvas.height = canvasSize * ratio;
    //     canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);

    //     // Redraw stored paths
    //     drawData.forEach(data => {
    //         ctx.fillStyle = data.color || 'black';  // Default to black if no color specified
    //         ctx.fillRect(data.x + canvasSize / 2, data.y + canvasSize / 2, 1, 1);
    //     });
    // }, [drawData]);

    useEffect(() => {
        const canvas = canvasRef.current;

        canvas.style.transform = `translate(${offset.x}px, ${offset.y}px) scale(${scale})`;

        const aX = window.innerWidth / 2;
        const aY = window.innerHeight / 2;
        // const bX = (aX - offset.x) / scale;
        // const bY = (aY - offset.y) / scale;

        // setDebugText(coords.x + ', ' + coords.y);

        // const handleTouchMove = (event) => {
        //     console.log("Touch count: " + event.touches.length);
        //     if (event.touches.length === 1 && dragging) {
        //         console.log("Dragging!");
        //         event.preventDefault();
        //         const touch = event.touches[0];
        //         const dx = touch.clientX - origin.x;
        //         const dy = touch.clientY - origin.y;

        //         setOffset(prevOffset => ({
        //             x: prevOffset.x + dx,
        //             y: prevOffset.y + dy
        //         }));

        //         setOrigin({ x: touch.clientX, y: touch.clientY });

        //         resetView();
        //     } else if (event.touches.length === 2) {
        //         event.preventDefault();
        //         const touch1 = event.touches[0];
        //         const touch2 = event.touches[1];
        //         const distance = Math.sqrt(Math.pow(touch2.clientX - touch1.clientX, 2) + Math.pow(touch2.clientY - touch1.clientY, 2));

        //         canvas.style.transformOrigin = "${Math.abs(touch2.clientX - touch1.clientX)}px ${Math.abs(touch2.clientY - touch1.clientY)}px";

        //         console.log("Double touch distance: " + distance);
        //         console.log("SD: " + JSON.stringify(startDistance));
        //         if (startDistance) {
        //             const scaleChange = distance / startDistance;
        //             console.log("Scale change: " + scaleChange);
        //             setScale(scale => scale * scaleChange);
        //         }

        //         setStartDistance(distance);
        //         console.log("Set start distance to " + distance);
        //     }
        // };

        const handleTouchMove = (event) => {
            if (event.touches.length === 1 && dragging) {
                event.preventDefault();
                const touch = event.touches[0];
                const dx = touch.clientX - origin.x;
                const dy = touch.clientY - origin.y;

                setOffset(prevOffset => ({
                    x: prevOffset.x + dx,
                    y: prevOffset.y + dy
                }));

                setOrigin({ x: touch.clientX, y: touch.clientY });

                resetView();
            } else if (event.touches.length === 2) {
                event.preventDefault();
                const touch1 = event.touches[0];
                const touch2 = event.touches[1];

                const currentDistance = Math.hypot(
                    touch2.clientX - touch1.clientX,
                    touch2.clientY - touch1.clientY
                );

                if (startDistance) {
                    const scaleChange = currentDistance / startDistance;
                    const newScale = scale * scaleChange;

                    const midPointX = (touch1.clientX + touch2.clientX) / 2;
                    const midPointY = (touch1.clientY + touch2.clientY) / 2;

                    const scaleRatio = newScale / scale;

                    const newOffsetX = midPointX - (midPointX - offset.x) * scaleRatio;
                    const newOffsetY = midPointY - (midPointY - offset.y) * scaleRatio;

                    setScale(newScale);
                    setOffset({ x: newOffsetX, y: newOffsetY });
                }

                setStartDistance(currentDistance);
            }
        };

        const handleTouchStart = (event) => {
            if (event.touches.length === 1) {
                console.log("Setting dragging to true");
                setDragging(true);
                const touch = event.touches[0];
                setOrigin({ x: touch.clientX, y: touch.clientY });
            } else if (event.touches.length === 2) {
                const touch1 = event.touches[0];
                const touch2 = event.touches[1];
                const distance = Math.sqrt(Math.pow(touch2.clientX - touch1.clientX, 2) + Math.pow(touch2.clientY - touch1.clientY, 2));
                setStartDistance(distance);
            }
        };

        // const handleTouchEnd = (event) => {
        //     if (!dragging && event.changedTouches.length > 0) {
        //         const touch = event.changedTouches[0];
        //         handleCanvasClick(touch);
        //     }

        //     setDragging(false);
        // };

        const handleTouchEnd = (event) => {
            setStartDistance(null);
            setDragging(false);
        }

        const handleTouchCancel = () => {
            setDragging(false);
        };

        // const handleGestureChange = (event) => {
        //     event.preventDefault();
        //     if (event.scale !== undefined) {
        //         const currentScale = scale;
        //         const scaleChange = (event.scale - 1) * 0.1;
        //         const newScale = Math.max(0.5, Math.min(currentScale + scaleChange, 5));
        
        //         setScale(newScale);
        //     }
        // };

        const handleWheel = (event) => {
            event.preventDefault();
            const { deltaX, deltaY, clientX, clientY } = event;
            
            // Scale adjustment factor
            const scaleChange = deltaY * -0.005;
            const newScale = Math.max(scale + scaleChange, 1);  // Prevent scale from going below 0.1
            
            const canvas = canvasRef.current;
            const rect = canvas.getBoundingClientRect();
            
            // Mouse position relative to the canvas
            const mouseX = (clientX - rect.left) / scale;
            const mouseY = (clientY - rect.top) / scale;
            
            // Adjust offset to zoom into the mouse position
            const newOffset = {
                x: offset.x - mouseX * scaleChange,
                y: offset.y - mouseY * scaleChange
            };
        
            setScale(newScale);
            setOffset(newOffset);
            resetView();

            adjustOffsetToBounds();
        };

        setSelectedPixel(null);

        // window.addEventListener('resize', drawPixels);
        canvas.addEventListener('wheel', handleWheel, { passive: false });
        canvas.addEventListener('touchmove', handleTouchMove, { passive: false });
        canvas.addEventListener('touchstart', handleTouchStart, { passive: false });
        canvas.addEventListener('touchend', handleTouchEnd, { passive: false });
        canvas.addEventListener('touchcancel', handleTouchEnd, { passive: false });
        // canvas.addEventListener('gesturechange', handleGestureChange, { passive: false });

        return () => {
            // window.removeEventListener('resize', drawPixels);
            canvas.removeEventListener('touchmove', handleTouchMove);
            canvas.removeEventListener('touchstart', handleTouchStart);
            canvas.removeEventListener('touchend', handleTouchEnd);
            canvas.removeEventListener('touchcancel', handleTouchEnd);
            // canvas.removeEventListener('gesturechange', handleGestureChange);
            canvas.removeEventListener('wheel', handleWheel);
        };
    }, [startDistance, offset, scale, dragging]);

    const handleCanvasClick = (event) => {
        const clientX = event.clientX || event.pageX;
        const clientY = event.clientY || event.pageY;

        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = Math.floor(((clientX - rect.left) / scale) - (canvasSize / 2));
        const y = Math.floor(((clientY - rect.top) / scale) - (canvasSize / 2));
        const found = drawData.find(p => p.x === x && p.y === y);

        const infoBoxWidth = 150;
        const infoBoxHeight = selectedPixel ? 300 : 50; // Todo: fix this. instead of selectedPixel it should ask if there's a pixel on that spot
        const infoBoxX = (window.innerWidth / 2) - (infoBoxWidth / 2);
        let infoBoxY;

        if (event.clientY >= window.innerHeight / 2) { // bottom half
            infoBoxY = 100;
        } else { // top half
            infoBoxY = event.clientY + 100;
        }

        setInfoBoxPosition({x: infoBoxX, y: infoBoxY});

        // console.log(infoBoxPosition);

        const c = getScreenCoordinates(x, y);
        setSelectedPixelPos({x: c.screenX, y: c.screenY});

        // setSelectedPixelPos({ x: x * scale + rect.left + (canvasSize / 2), y: y * scale + rect.top + (canvasSize / 2) });

        if (found) {
            setSelectedPixel({
                ...found,
                message: `Placed by ${sanitizeInput(found.user)} at ${new Date(found.date).toLocaleString()}`,
                note: sanitizeInput(found.note),
                action: pixelsLeft > 0 ? `Replace` : `Buy pixels`
            });
        }
        else {
            setSelectedPixel({
                x, y, message: "Be the first to place!",
                action: pixelsLeft > 0 ? `Place` : `Buy pixels`
            });
        }
    };

    const drawPixel = () => {
        try {
            if (!selectedPixel)
                return;

            if (pixelsLeft === 0) {
                window.open('/');
            } else {
                const { x, y, action } = selectedPixel;
                const signature = Cookies.get('userSignature');
                const type = Cookies.get('signType');
                fetch((DEBUG ? 'http://localhost:5000' : '') + '/pixels', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ x, y, note, color: currentColor, signature: (type === 'sol' ? Buffer.from(signature, 'base64') : signature), type, publicKey: Cookies.get('key') }),
                })
                    .then(response => response.json())
                    .then(pixel => {
                        // pixel.x -= canvasSize / 2;
                        // pixel.y -= canvasSize / 2;
                        setDrawData([...drawData.filter(p => p.x !== x || p.y !== y), pixel]);
                        // pixelDataRef.current = [...pixelDataRef.current.filter(p => p.x !== x || p.y !== y), pixel];
                        // drawNewPixel(pixel);

                        setSelectedPixel(null);
                        setPixelsLeft(pixelsLeft - 1);
                        setNote('');
                    })
                    .catch(error => console.error('Error:', error));
            }
        } catch (e) {
            alert("Error placing pixel: " + e);
        }
    };

    const startDrag = (event) => {
        // if (event.button === 2) {
        setDragging(true);
        setOrigin({ x: event.clientX, y: event.clientY });
        event.preventDefault();
        // }
    };

    const endDrag = () => {
        setDragging(false);
        adjustOffsetToBounds();
    };

    const doDrag = (event) => {
        if (dragging) {
            const dx = event.clientX - origin.x;
            const dy = event.clientY - origin.y;
            const tentativeNewX = offset.x + dx;
            const tentativeNewY = offset.y + dy;
    
            // Calculate bounds based on the current scale and window size
            const maxX = window.innerWidth - canvasSize * scale;
            const maxY = window.innerHeight - canvasSize * scale;
    
            // Apply temporary constraints
            // const newOffsetX = Math.min(0, Math.max(tentativeNewX, maxX));
            // const newOffsetY = Math.min(0, Math.max(tentativeNewY, maxY));
            const newOffsetX = tentativeNewX;
            const newOffsetY = tentativeNewY;
    
            setOffset({ x: newOffsetX, y: newOffsetY });
            setOrigin({ x: event.clientX, y: event.clientY });

            resetView();
        }
    };
    
    // Example usage: 
    // const { x, y } = getGridCoordinates(screenX, screenY);
    // console.log(`Grid coordinates: (${x}, ${y})`);

    function resetView() {
        // const minY = 50 * (scale - 1) * 10;
        // setUsername(offset.x + ", " + offset.y + " (" + window.innerWidth + ", " + window.innerHeight + ") " + scale + " / " + minY);

        // setOffset({
        //     x: offset.x,
        //     y: Math.max(minY, offset.y)
        // });

        // const coords = getGridCoordinates(0, 0);

        // setUsername(offset.x + ", " + offset.y);
    }

    const adjustOffsetToBounds = () => {
        // const canvas = canvasRef.current;
        const newOffset = {
            // x: Math.min(0, Math.max(offset.x, window.innerWidth - canvasSize * scale)),
            // y: Math.min(0, Math.max(offset.y, window.innerHeight - canvasSize * scale))
            x: offset.x,
            y: offset.y,
        };
    
        if (newOffset.x !== offset.x || newOffset.y !== offset.y) {
            setOffset(newOffset);
            resetView();
        }
    };

    const changeColor = (color) => {
        setCurrentColor(color);
    };

    const getColorStyle = (color) => ({
        backgroundColor: color,
        width: '20px',
        height: '20px',
        margin: '5px',
        borderRadius: '50%',
        border: currentColor === color ? '2px solid #AAA' : '2px solid transparent',
        padding: '0',
        boxSizing: 'border-box',
    });

    // const zoomIn = () => {
    //     setScale(scale => scale * 1.1); // Increase scale by 10%
    // };

    const zoomIn = () => {
        const scaleAmount = 1.1;
        const newScale = scale * scaleAmount;
    
        const clientX = window.innerWidth / 2;
        const clientY = window.innerHeight / 2;
    
        const scaleRatio = newScale / scale;
    
        const newOffsetX = clientX - (clientX - offset.x) * scaleRatio;
        const newOffsetY = clientY - (clientY - offset.y) * scaleRatio;
    
        setScale(newScale);
        setOffset({ x: newOffsetX, y: newOffsetY });

        // drawPixels();
    };


    // const zoomOut = () => {
    //     setScale(scale => Math.max(scale * 0.9, 0.1)); // Decrease scale by 10%, minimum scale of 0.1
    // };

    const zoomOut = () => {
        const scaleAmount = 1.1;
        const newScale = scale / scaleAmount;

        const clientX = window.innerWidth / 2;
        const clientY = window.innerHeight / 2;

        const scaleRatio = newScale / scale;

        const newOffsetX = clientX - (clientX - offset.x) * scaleRatio;
        const newOffsetY = clientY - (clientY - offset.y) * scaleRatio;

        setScale(newScale);
        setOffset({ x: newOffsetX, y: newOffsetY });

        // drawPixels();
    };


    const zoomButtonStyle = {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: 'white',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '18px',
        padding: '10px',
        transition: 'background-color 0.3s',
        height: '100%',
        boxSizing: 'border-box',
        userSelect: 'none',
    };

    const zoomButtonStyleLeft = {
        ...zoomButtonStyle,
        borderRadius: '0 0 0 8px',
    };

    const zoomButtonStyleRight = {
        ...zoomButtonStyle,
        borderRadius: '0 0 8px 0',
    };

    const zoomButtonHoverEffect = {
        '&:hover': {
            backgroundColor: '#6ba4f8',
        },
    };

    const combZoomButtonStyleLeft = {
        ...zoomButtonStyleLeft, ...zoomButtonHoverEffect
    };

    const combZoomButtonStyleRight = {
        ...zoomButtonStyleRight, ...zoomButtonHoverEffect
    }

    const toggleLb = () => {
        setLbVisible(!lbVisible);
    };

    const nextPage = () => {
        setLbPage((prev) => (prev + 1) < (lb.length / 10) ? prev + 1 : prev);
    };

    const prevPage = () => {
        setLbPage((prev) => (prev - 1 >= 0) ? prev - 1 : prev);
    };

    const goHome = () => {
        navigate('/');
    }

    const textRef = useRef(null);

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f0f0ff',
            position: 'relative'
        }}>

        <div className="background-text" style={{ top: '-100%', whiteSpace: 'nowrap' }}>
            <p style={{ color: 'rgba(66, 135, 245, 0.2)' }} className="scrolling-text-r">Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     </p>
        </div>

        <div className="background-text" style={{ top: '-80%', whiteSpace: 'nowrap' }}>
            <p style={{ color: 'rgba(35, 161, 31, 0.25)' }} className="scrolling-text">
                $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK
            </p>
        </div>

        <div className="background-text" style={{ top: '-60%', whiteSpace: 'nowrap' }}>
        <p style={{ color: 'rgba(66, 135, 245, 0.2)' }} className="scrolling-text-r">Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     </p>
        </div>

        <div className="background-text" style={{ top: '-40%', whiteSpace: 'nowrap' }}>
            <p style={{ color: 'rgba(35, 161, 31, 0.25)' }} className="scrolling-text">
                $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK
            </p>
        </div>

        <div className="background-text" style={{ top: '-20%', whiteSpace: 'nowrap' }}>
        <p style={{ color: 'rgba(66, 135, 245, 0.2)' }} className="scrolling-text-r">Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     </p>
        </div>

        <div className="background-text" style={{ top: '0%', whiteSpace: 'nowrap' }}>
            <p style={{ color: 'rgba(35, 161, 31, 0.25)' }} className="scrolling-text">
                $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK
            </p>
        </div>

        <div className="background-text" style={{ top: '20%', whiteSpace: 'nowrap' }}>
        <p style={{ color: 'rgba(66, 135, 245, 0.2)' }} className="scrolling-text-r">Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     </p>
        </div>

        <div className="background-text" style={{ top: '40%', whiteSpace: 'nowrap' }}>
            <p style={{ color: 'rgba(35, 161, 31, 0.25)' }} className="scrolling-text">
                $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK
            </p>
        </div>

        <div className="background-text" style={{ top: '60%', whiteSpace: 'nowrap' }}>
        <p style={{ color: 'rgba(66, 135, 245, 0.2)' }} className="scrolling-text-r">Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     </p>
        </div>

        <div className="background-text" style={{ top: '80%', whiteSpace: 'nowrap' }}>
            <p style={{ color: 'rgba(35, 161, 31, 0.25)' }} className="scrolling-text">
                $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK     $ZACK
            </p>
        </div>

        <div className="background-text" style={{ top: '100%', whiteSpace: 'nowrap' }}>
        <p style={{ color: 'rgba(66, 135, 245, 0.2)' }} className="scrolling-text-r">Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     Web3/Place     </p>
        </div>


            <div style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                backgroundColor: '#333',
                padding: '10px',
                borderRadius: '5px',
                color: 'white',
                zIndex: 10
            }}>
                <button onClick={toggleLb} style={{
                    borderRadius: '4px',
                    backgroundColor: '#4287f5',
                    color: '#FFFFFF',
                    fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
                    fontSize: '16px',
                    fontWeight: '600',
                    border: 'none'
                }}>Leaderboard</button>
                {lbVisible && (
                    <div>
                        {lb.slice(lbPage * 10, (lbPage + 1) * 10).map((user, index) => (
                            <div key={index} style={{
                                fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '600'
                            }}>{user.name}: {user.pixels} pixels</div>
                        ))}
                        <button onClick={prevPage} disabled={lbPage === 0} style={{
                            borderRadius: '4px',
                            backgroundColor: '#4287f5',
                            color: '#FFFFFF',
                            fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
                            fontSize: '16px',
                            fontWeight: '600',
                            border: 'none'
                        }}>⬅️</button>
                        <button onClick={nextPage} disabled={(lbPage + 1) * 10 >= lb.length} style={{
                            borderRadius: '4px',
                            backgroundColor: '#4287f5',
                            color: '#FFFFFF',
                            fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
                            fontSize: '16px',
                            fontWeight: '600',
                            alignItems: 'right',
                            border: 'none'
                        }}>➡️</button>
                    </div>
                )}
            </div>
            
            <div style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                padding: '10px',
                // backgroundColor: 'rgba(7, 8, 11, 0.8)',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                zIndex: '100000',
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }}>
                {userId != -1 && (
                    <>
                    <p style={{color: 'white', margin: '0'}}>Username: {username}</p>
                    <p style={{color: 'white', margin: '0'}}>Pixels: {pixelsLeft}</p>
                    <div>
                        {colorOptions.map((color, index) => (
                            <>
                                <button
                                    key={color}
                                    onClick={() => changeColor(color)}
                                    style={getColorStyle(color)}
                                ></button>
                                {(index + 1) % 5 === 0 && <br />}
                            </>
                        ))}
                    </div>
                        <button onClick={() => setGiftDialogVisible(true)} className="squishy" style={{ marginLeft: '10px' }}>🎁</button>
                        {
    giftDialogVisible && (
        <div style={{ 
            position: 'fixed', 
            top: 0, 
            left: 0, 
            width: '100vw', 
            height: '100vh', 
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }} onClick={() => setGiftDialogVisible(false)}>
            <div style={{
                width: '300px',
                padding: '20px',
                backgroundColor: '#0c4cb3',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                position: 'relative',
                zIndex: 1001
            }} onClick={e => e.stopPropagation()}>
                <button style={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                    border: 'none',
                    background: 'transparent',
                    fontSize: '16px',
                    cursor: 'pointer',
                    color: 'white'
                }} onClick={() => setGiftDialogVisible(false)}>x</button>
                <h1 style={{ textAlign: 'center', color: 'white' }}>Gift Pixels</h1>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                    <button onClick={() => setGiftAmount(giftAmount > 1 ? giftAmount - 1 : 1)} style={{ fontSize: '20px', color: 'white', background: 'transparent', border: 'none' }}>−</button>
                    <input type="number" value={giftAmount} onChange={handleGiftAmountChange} min="1" max={pixelsLeft} style={{ width: '50px', textAlign: 'center', margin: '0 10px', color: 'white', background: 'transparent', border: '1px solid white' }} />
                    <button onClick={() => setGiftAmount(giftAmount < pixelsLeft ? giftAmount + 1 : pixelsLeft)} style={{ fontSize: '20px', color: 'white', background: 'transparent', border: 'none' }}>+</button>
                </div>
                {/* <h2 style={{ textAlign: 'center', color: 'white' }}>Gift to...</h2>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className="squishy" style={{ marginRight: '10%', color: 'white' }} onClick={() => setGiftMethod(1)}>A friend</button>
                    <button className="squishy" style={{ marginLeft: '10%', color: 'white' }} onClick={() => setGiftMethod(2)}>Active users</button>
                </div>
                <p style={{ textAlign: 'center', color: 'white' }}>{giftText}</p> */}
                <button className="squishy" onClick={sendGift} style={{ width: '100%', disabled: giftMethod === 0 }}>Send</button>
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <input type="text" value={redeemCode} onChange={e => setRedeemCode(e.target.value)} placeholder="Enter gift code" style={{ width: '50%', textAlign: 'center', marginBottom: '20px', color: 'white', background: 'transparent', border: '1px solid white' }} />
                    <button onClick={handleRedeem} className="squishy" style={{ width: '100%', color: 'white' }}>Redeem</button>
                </div>
                {giftCodes.length > 0 && (
                    <div style={{ marginTop: '20px' }}>
                        <h2 style={{ color: 'white' }}>Your Gift Codes:</h2>
                        {giftCodes.map((code, index) => (
                            <div key={index} style={{ textAlign: 'center', color: 'white' }}>
                                Code: {code.code} - Pixels: {code.pixels}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
                    </>
                )}
                {userId == -1 && (
                    <>
                    <button onClick={goHome} style={{
                        borderRadius: '4px',
                        backgroundColor: '#4287f5',
                        color: '#FFFFFF',
                        fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
                        fontSize: '16px',
                        fontWeight: '600',
                        border: 'none'
                    }}>{debugText}</button>
                    </>
                )}
                <div style={{ display: 'flex', width: '100%', height: '40px', userSelect: 'none' }}>
                    <button onClick={zoomIn} style={ combZoomButtonStyleLeft }>+</button>
                    <button onClick={zoomOut} style={ combZoomButtonStyleRight }>-</button>
                </div>
            </div>

            <canvas
                ref={canvasRef}
                onClick={handleCanvasClick}
                onMouseDown={startDrag}
                onMouseUp={endDrag}
                onMouseLeave={endDrag}
                onMouseMove={doDrag}
                onContextMenu={(e) => e.preventDefault()}
                style={{
                    cursor: 'crosshair',
                    imageRendering: 'pixelated',
                    display: 'block',
                    margin: 'auto',
                    backgroundColor: '#fff',
                    zIndex: 5,
                    width: `${canvasSize}px`,
                    height: `${canvasSize}px`,
                    transformOrigin: '0 0',
                }}
            />
            {renderNotes()}
            {selectedPixel && (
                <>
                <div style={{
                    position: 'absolute',
                    left: `${selectedPixelPos.x}px`,
                    top: `${selectedPixelPos.y}px`,
                    width: `${scale}px`,
                    height: `${scale}px`,
                    border: '1px solid black',
                    boxSizing: 'border-box',
                    pointerEvents: 'none',
                    zIndex: 1000
                }} />
                <div
                style={{
                    position: 'absolute',
                    left: `${infoBoxPosition.x}px`,
                    top: `${infoBoxPosition.y}px`,
                    padding: '10px',
                    width: '200px',
                    maxHeight: '300px',
                    backgroundColor: 'white',
                    border: '1px solid black',
                    zIndex: 1000, // Ensure it's above the canvas
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                    <p style={{ margin: '0', textAlign: 'center', display: 'block', wordWrap: 'break-word' }}>{selectedPixel.message}</p>
                    {sanitizeInput(selectedPixel.note) && (<><br></br><i style={{ textAlign: 'center', display: 'block', userselect: 'none' }}>"{sanitizeInput(selectedPixel.note)}"</i></>)}
                    {pixelsLeft > 0 && (<p>Enter a note for others to see:</p>)}
                    {pixelsLeft > 0 && (<input type="text" id="noteinput" value={note} onChange={onNoteChange}></input>)}
                    <br />
                    <button className="click" onClick={() => drawPixel()}>{selectedPixel.action}</button>
                </div>
                </>
            )}
        </div>
    );
};

export default CanvasComponent;
